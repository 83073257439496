import React from "react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Review from "../Assets/img/review.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Heading from "../ui/Heading";
import { isDesktop, isMobile } from "react-device-detect";
import LeftIcon from "../../../../Assets/images/icon/left.svg"
import RightIcon from "../../../../Assets/images/icon/right.svg"

const Customer = () => {
  const [customerData, setCustomerData] = React.useState([]);
  React.useEffect(() => {
    GetCustomerRewies();
    console.log("customerdta", customerData);
  }, []);
  const GetCustomerRewies = () => {
    axios
      .get(`${base_url}/common/site_reviews`, {
        headers: postHeader,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          setCustomerData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const LeftArrow = (props) => {
    return (
      <div className="slick-arrow slick-prev" {...props}>
        <img src={LeftIcon}  loading="lazy" alt="Left Arrow" />
      </div>
    );
  };

  const RightArrow = (props) => {
    return (
      <div className="slick-arrow slick-next" {...props}>
        <img src={RightIcon}   loading="lazy" alt="Right Arrow" />
      </div>
    );
  };


  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const reviewData = [
    {
      username: "Diana Symonova",
      review: "Manuel is very perfectionistic and passionate about his work. Without doubt the best jeweler in Marbella. He has done a lot of different work for my jewelry (fixing, cleaning, polishing, rhodium plating) always the best quality and very ",
      date: "a week ago",
      rating: "5"
    },
    {
      username: "Joe Hanson",
      review: "This beautiful man kindly opened his store early for me as he could see I was interested in the fine jewelry. My wife had lost her diamond necklace and rather than me being pecky,",
      date: "a week ago",
      rating: "5"
    },
    {
      username: "Marc Spierings",
      review: "I can only highly recommend this Diamond dealer. Manuel is extremely professional and has been able to provide me the best possible diamond for my engagement ring. ",
      date: "3 months ago",
      rating: "5"
    },
    {
      username: "Robert Ruse",
      review: "How lucky we were to find Manuel Spinosa jewellry design where we bought the most beautifully cut aquamarine necklace set in white gold and diamonds.",
      date: "3 months ago",
      rating: "5"
    },
    {
      username: "Анастасия Балакина",
      review: "Manuel the best jeweler I met in Spain. Always ready to do something special just for you🩷",
      date: "2 weeks ago",
      rating: "5"
    },


  ]
  return (
    <section className="rcs_customer_say_section section-space">
      <Container fluid className="home-showcase-container">
        {/* <div className="d-flex align-items-center justify-content-between pb-3"> */}
        <Heading>
          <h2>Customers Who Trust Us!</h2>
          <p>Crafting smiles with every exquisite
            piece of jewelry</p>
        </Heading>

        {/* {isDesktop && (
            <div className="rcs_customer_review_btn">
              <Button
                className="btn outline-base-btn large"
                onClick={() =>
                  window.open(customerData?.google_review_url, "_blank")
                }
              >
                {" "}
                Write a Review{" "}
              </Button>
            </div>
          )} */}
        {/* </div> */}

        <Row>
          <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
            <div className="rcs_customer_review_slider">
              <Slider className="rcs_customer_say_inner" {...settings2}>
                {reviewData?.map((customdata) => (
                  <div className="rcs_customer_review_slide_item_outer">
                    <div className="rcs_customer_review_slide_item">
                      <div className="rcs_customer_slide_title_img mb-2">
                        <div className="rcs_customer_review_slide_img">
                          {/* <LazyLoadImage
                          src={Review}
                          alt="Google Review Image"
                          onClick={() =>
                            window.open(
                              "https://www.google.com/search?client=firefox-b-d&q=belgium+webnet#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,,",
                              "_blank"
                            )
                          }
                        /> */}
                          <Rating
                            name="half-rating-read"
                            defaultValue={5}
                            precision={customdata?.rating}
                            size="medium"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="rcs_customer_slide_text_content mb-2">
                        <p>
                          {/* {customdata?.review?.slice(0, 150)} */}
                          {/* {customdata?.review?.length > 120 ? "..." : ""} */}
                          <span>
                            {customdata?.review}
                          </span>
                          <Link
                            to="#"
                            onClick={() =>
                              window.open(
                                "https://www.google.com/search?q=manuel+spinosa+jewellery&oq=Manuel+Spinosa+Jewellery&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyCAgCEAAYFhgeMgoIAxAAGIAEGKIEMgoIBBAAGIAEGKIEMgYIBRBFGDwyBggGEEUYPDIGCAcQRRg90gEHMjkzajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0xd7327f8eb2ad063:0x8289ca7266c413bc,1,,,,",
                                "_blank"
                              )
                            }
                          >
                            {" "}
                            Read More...{" "}
                          </Link>
                        </p>

                      </div>
                      <div className="rcs_customer_review_footer d-flex align-items-center">
                        <div className="name-charAt mr-3">
                          {customdata?.username.charAt(0)}
                        </div>
                        <div className="rcs_customer_review_slide_title">
                          <h3>{customdata?.username}</h3>
                          <p>{customdata?.date}</p>

                        </div>
                      </div>
                    </div>
                  </div>


                ))}
              </Slider>
            </div>
          </Col>
        </Row>

        {/* {isMobile && (
          <div className="rcs_customer_review_btn text-center mt-5">
            <Button
              className="btn outline-base-btn large"
              onClick={() =>
                window.open(customerData?.google_review_url, "_blank")
              }
            >
              {" "}
              Write a Review{" "}
            </Button>
          </div>
        )} */}
      </Container>
    </section>
  );
};

export default Customer;
