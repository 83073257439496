import { Button, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import OrderImage from "../../../Assets/images/sucess.png";
import { setOrderSuccess } from "../../../Reducer/homeReducer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { base_url, postHeader } from "../../../Helpers/request";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader";

function RedsysMiddelRoute() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  const currency = useSelector(
    (state) => state.persistedReducer.home.currencyicon
  );
  const session_Id = useSelector(
    (state) => state.persistedReducer.home.session_id
  );
  const user = useSelector((state) => state.persistedReducer.home.userData);

  const orderData = useSelector(
    (state) => state.persistedReducer.home.orderSuccessData
  );

  const dispatch = useDispatch();
  const Ds_SignatureVersion = searchParams.get("Ds_SignatureVersion");
  const Ds_MerchantParameters = searchParams.get("Ds_MerchantParameters");
  const Ds_Signature = searchParams.get("Ds_Signature");
  const redSysPaymentFire = async () => {
    // if (!orderData) {
    //   alert("Please enter a valid amount.");
    //   return;
    // }
    // result=resultresultresultresultresultresultresultresultresultresult=========== {
    //   Ds_SignatureVersion: 'HMAC_SHA256_V1',
    //   Ds_MerchantParameters: 'eyJEU19NRVJDSEFOVF9BTU9VTlQiOiIxMDAiLCJEU19NRVJDSEFOVF9PUkRFUiI6IjEyMzEyMyIsIkRTX01FUkNIQU5UX01FUkNIQU5UQ09ERSI6IjEyMzEyMzEyMyIsIkRTX01FUkNIQU5UX0NVUlJFTkNZIjoiOTc4IiwiRFNfTUVSQ0hBTlRfVFJBTlNBQ1RJT05UWVBFIjoiMCIsIkRTX01FUkNIQU5UX1RFUk1JTkFMIjoiMSIsIkRTX01FUkNIQU5UX01FUkNIQU5UTkFNRSI6IlJFRFNZUyBQQVkgU0hPUCIsIkRTX01FUkNIQU5UX01FUkNIQU5UVVJMIjoiaHR0cDovL3Nob3AuanMuZ2wvbWVyY2hhbnQiLCJEU19NRVJDSEFOVF9VUkxLTyI6Imh0dHA6Ly9zaG9wLmpzLmdsL2Vycm9yIiwiRFNfTUVSQ0hBTlRfVVJMT0siOiJodHRwOi8vc2hvcC5qcy5nbC9zdWNjZXNzIn0=',
    //   Ds_Signature: 'lVsHcAKPtthlFChEe6PQhMrkdqX94NM8EMS/UXkjy8Q='
    // }

    // setLoader(true);
    const data = {
      currency_code: currencycode,
      user_id: orderData?.billing_address?.user_id,
      order_id: orderData?.order_number,
      Ds_SignatureVersion,
      Ds_MerchantParameters,
      Ds_Signature,
    };
    // Make the API call
    axios
      .post(base_url + "/redsyspay/success", data, {
        // Payload
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          history.push("/success");
        } else {
            history.push("/failure");
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (Ds_SignatureVersion && Ds_MerchantParameters && Ds_Signature) {
      redSysPaymentFire();
    }
  }, [searchParams]);
  return (
    <div className="container">
      <Row>
        <Col>
          <div className="payment_processing_loder_box">
            <h1>Payment processing, please wait...</h1>
              <CircularProgress className="rcs_filter_loader" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RedsysMiddelRoute;
