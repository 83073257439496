import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import Switch from "react-switch";

const CookiesComponent = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    functional: false,
    analytics: false,
    performance: false,
    advertising: false,
  });


  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
  };


  const [cookieConsentGiven, setCookieConsentGiven] = useState(false);

  // Check if the cookie consent has already been given
useEffect(() => {
  setPreferences((prev) => ({ ...prev, ["necessary"]: !prev[true] }));
  const consent = document.cookie.includes("cookieConsent=true");
  setCookieConsentGiven(consent);
  if (consent) {
    const savedPreferences = JSON.parse(localStorage.getItem("cookiePreferences"));
    if (savedPreferences) {
      setPreferences(savedPreferences);
    }
  }
}, []);

  // Save preferences to localStorage and cookies
  const savePreferences = () => {
    localStorage.setItem("cookiePreferences", JSON.stringify(preferences));
    document.cookie = "cookieConsent=true; path=/; max-age=86400"; // Store for 1 year
    setShowPreferences(false);
    setCookieConsentGiven(true);
    enableTracking();
  };

  // Handle enabling of tracking based on preferences
  const enableTracking = () => {
    if (preferences.analytics) {
      const trackingId = "G-SN3K26LY09"; // Replace with your GA4 measurement ID
      if (window.gtag) {
        window.gtag("config", trackingId, { anonymize_ip: true });
      }
    }
    localStorage.setItem("cookiePreferences", JSON.stringify(preferences));
    document.cookie = "cookieConsent=true; path=/; max-age=86400"; // Store for 1 year
    setShowPreferences(false);
    setCookieConsentGiven(true);
  };

  // Toggle individual preferences
  const handleTogglePreference = (category) => {
    setPreferences((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  return (
    <div>
      {!cookieConsentGiven && (
        <CookieConsent
          location="top"
          buttonText="Accept All"
          cookieName="myAwesomeCookieName"
          style={{ background: "#222" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px", background: "#fff" }}
          expires={150}
          enableDeclineButton
          declineButtonText="Decline"
          declineButtonStyle={{ color: "#fff", background: "#f00", fontSize: "13px" }}
          onAccept={() => {
            enableTracking();
          }}
          onDecline={() => {
            console.log("User declined tracking");
          }}

        >
          We use cookies to improve your experience on our site. By using our site, you agree to our use of cookies.

          <div className="prefrenceBtn">
            <button
              className="btn-none-border"
              onClick={() => { setShowPreferences(true); setCookieConsentGiven(true) }}
            >
              Preferences
            </button>
          </div>
        </CookieConsent>
      )}

      {showPreferences && (
        <div
          className="cookiesModal"
        >
          <h3>Customize Cookie Preferences</h3>
          <div>
              <label className="d-flex align-item-center justify-content-between">
                <strong>Necessary</strong>
                <Switch checked={preferences.necessary}
               checkedIcon={true} onColor={"#B9994B"} ofColor={"#F5F8F9"} disabled/>
              </label>
              <p>Necessary cookies are required for the website to function.</p>
            </div>
          <hr />
          <div className="modal-view">
            <div>
              <label className="d-flex align-item-center justify-content-between">
                <strong>Functional</strong>
                <Switch checked={preferences.functional}
                  onChange={() => handleTogglePreference("functional")} checkedIcon={false} onColor={"#B9994B"} ofColor={"#F5F8F9"} />
              </label>
              <p>Functional cookies help perform certain functionalities such as sharing website content on social media platforms, collecting comments and other third-party features.</p>
            </div>
            <div>
              <label className="d-flex align-item-center justify-content-between">
                <strong>Analytics</strong>
                <Switch checked={preferences.analytics}
                  onChange={() => handleTogglePreference("analytics")} checkedIcon={false} onColor={"#B9994B"} ofColor={"#F5F8F9"} />
              </label>
              <p>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
            </div>
            <div>
              <label className="d-flex align-item-center justify-content-between">
                <strong>Performance</strong>
                <Switch checked={preferences.performance}
                  onChange={() => handleTogglePreference("performance")} checkedIcon={false} onColor={"#B9994B"} ofColor={"#F5F8F9"} />
              </label>
              <p>Performance cookies are used to understand and analyze key performance indices of the website, helping to provide a better user experience for visitors.</p>
            </div>
            <div>
              <label className="d-flex align-item-center justify-content-between">
                <strong>Advertising</strong>
                <Switch checked={preferences.advertising}
                  onChange={() => handleTogglePreference("advertising")} checkedIcon={false} onColor={"#B9994B"} ofColor={"#F5F8F9"} />
              </label>
              <p>Advertising cookies are used to deliver personalized ads to visitors based on the pages they previously visited and to analyze the effectiveness of the advertising campaign.**</p>

            </div>
          </div>
          <div className="d-flex align-item-center justify-content-between" style={{ marginTop: "20px", textAlign: "right" }}>
            <button
              className="golden-btn"
              onClick={enableTracking}
            >
              Accept All
            </button>
            <div className="d-flex align-item-center  ">
              <button
                className="btn-none-border mr-3"
                onClick={savePreferences}
                disabled={
                  preferences?.functional ||
                  preferences?.analytics  ||
                  preferences?.performance ||
                  preferences?.advertising ? false : true
                }
              >
                Save Preferences
              </button>


              <button
                className="btn-none-border"
                onClick={() =>{  localStorage.setItem("cookiePreferences", JSON.stringify(preferences));
                  document.cookie = "cookieConsent=true; path=/; max-age=86400"; // Store for 1 year
                  setShowPreferences(false);
                  setCookieConsentGiven(true);}}
              >
                Cancel
              </button>
            </div>
          </div>
          {/* preferences?.functional ||
                  preferences?.analytics  ||
                  preferences?.performance ||
                  preferences?.advertising ? false : true */}
        </div>
      )}
    </div>
  );
};

export default CookiesComponent;
