import React from 'react'
import { Container } from 'react-bootstrap'
import Heading from "../ui/Heading";
import Slider from "react-slick";
import Img2 from "../Assets/img/manuelExprience-1.svg"
import Img1 from "../Assets/img/manuelExprience-2.svg"
import Img3 from "../Assets/img/manuelExprience-3.svg"
import Img4 from "../Assets/img/manuelExprience-4.svg"
export default function ManuelExprience() {



    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="section-space pb-lg-0">
            <Container className="manuel_exprience_section">
                <Heading>
                    <h2> The Manuel Spinosa experience </h2>
                    <p> Quality Craftsmanship with Guaranteed Trust, We Offer </p>
                </Heading>

                <div className="manuel_exprience_container">
                    <Slider {...settings}>

                        <div className="manuel_exprience_box">
                            <img src={Img1}  loading="lazy" alt="Image one" className="d-block mx-auto" />
                            <h2> Extensive Experience <br />
                                in our sector </h2>
                        </div>

                        <div className="manuel_exprience_box">
                            <img src={Img2}  loading="lazy" alt="Image Two" className="d-block mx-auto" />
                            <h2> Custom Jewelry  <br />
                                Design </h2>
                        </div>

                        <div className="manuel_exprience_box">
                            <img src={Img3}  loading="lazy" alt="Image Three" className="d-block mx-auto" />
                            <h2> Low prices without  <br />
                                intermediaries</h2>
                        </div>

                        <div className="manuel_exprience_box">
                            <img src={Img4}  loading="lazy" alt="Image Four" className="d-block mx-auto" />
                            <h2> Only top quality  <br />
                                GIA certified diamond </h2>
                        </div>

                    </Slider>
                </div>
            </Container>
        </section>
    )
}
