import React from "react";
import Heading from "../ui/Heading";
import Slider from "react-slick";
import { Container, Image } from "react-bootstrap";
import Halo from "../Assets/img/ring-style/new/halo.webp";
import Solitaire from "../Assets/img/ring-style/new/solitaire.webp";
import ThreeStone from "../Assets/img/ring-style/new/three-stone.webp";
import SingleRow from "../Assets/img/ring-style/new/single-row.webp";
import Eternity from "../Assets/img/ring-style/new/eternity-ring.webp";
import Fancy from "../Assets/img/ring-style/new/fancy.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SlickLeft from "../Assets/img/left.png";
import SlickRight from "../Assets/img/right.png";
import { Je_welryspelling } from "../../../../Helpers/request";
import LeftIcon from "../../../../Assets/images/icon/left.svg"
import RightIcon from "../../../../Assets/images/icon/right.svg"
const ShopRingByStyle = () => {



  const LeftArrow = (props) => {
    return (
      <div className="slick-arrow slick-prev" {...props}>
        <img src={LeftIcon} alt="Left Arrow" />
      </div>
    );
  };

  const RightArrow = (props) => {
    return (
      <div className="slick-arrow slick-next" {...props}>
        <img src={RightIcon} alt="Right Arrow" />
      </div>
    );
  };



  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <section className="shop-ring-by-style">
      <Container fluid className="home-showcase-container">
        <Heading>
          <h2> Shop Rings By Style </h2>
          <p>Explore {Je_welryspelling} Collections by Style and Make a Statement.</p>
        </Heading>
        <div className="inner-shop-diamond-shape">
          <Slider {...settings2}>
            <div className="item">
              <Link to="/engagement-rings/color=fancy" className="pt-0">
                <Image  loading="lazy" src={Fancy} alt="Fancy" />
              </Link>
              <Link to="/engagement-rings/color=fancy">Fancy</Link>
            </div>
            <div className="item">
              <Link to="/engagement-rings/style=Halo" className="pt-0">
                <Image  loading="lazy" src={Halo} alt="Halo" />
              </Link>
              <Link to="/engagement-rings/style=Halo">Halo</Link>
            </div>
            <div className="item">
              <Link to="/engagement-rings/style=Solitaire" className="pt-0">
                <Image  loading="lazy" src={Solitaire} alt="Solitaire" />
              </Link>
              <Link to="/engagement-rings/style=Solitaire">Solitaire</Link>
            </div>
            <div className="item">
              <Link to="/engagement-rings/style=Three%20stone" className="pt-0">
                <Image  loading="lazy" src={ThreeStone} alt="ThreeStone" />
              </Link>
              <Link to="/engagement-rings/style=Three%20stone">Three Stone</Link>
            </div>
            {/* <div className="item">
              <Link to="/engagement-rings/style=Single Row" className="pt-0">
                <Image  loading="lazy" src={SingleRow} alt="SingleRow" />
              </Link>
              <Link to="/engagement-rings/style=Single Row">Single Row</Link>
            </div> */}
            <div className="item">
              <Link to="/wedding-rings" className="pt-0">
                <Image  loading="lazy" src={Eternity} alt="Eternity" />
              </Link>
              <Link to="/wedding-rings">Eternity Rings</Link>
            </div>
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default ShopRingByStyle;
