import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import RingOneImg from "../../../../Assets/images/home/engagement-rings.webp"
import RingTwoImg from "../../../../Assets/images/home/wedding-bands.webp"
import { Link } from 'react-router-dom'
export default function TwoRingCards() {
    return (
        <section className="section-space">
            <Container fluid className="home-showcase-container two_ring_cards_section">
                <Row>
                    <Col xs="12" className="card_container">
                        <div>
                            <div className="img-box">
                                <img src={RingOneImg} loading="lazy" alt="Engaement Ring" />
                            </div>
                            <Link to="/engagement-rings">  <h3>Engagement Rings</h3></Link>
                            <Link to="/engagement-rings">      <button className="golden-btn mx-auto"> Shop Now</button> </Link>

                        </div>
                        <div>
                            <div className="img-box">
                                <img src={RingTwoImg} loading="lazy" alt="Wedding Ring" />
                            </div>
                            <Link to="/wedding-rings"> <h3> Wedding Bands </h3></Link>
                            <Link to="/wedding-rings"> <button className="golden-btn mx-auto"> Shop Now</button> </Link>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
