
import React, { useEffect } from 'react'
import { Button } from '@material-ui/core'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link,useHistory } from 'react-router-dom'
import OrderImage from '../../../Assets/images/sucess.png'
import { setOrderSuccess } from '../../../Reducer/homeReducer'
import { useLocation } from 'react-router-dom'
function RedsysPayementFailed() {
       const history=useHistory()
        const location = useLocation();
        const metaDetails = useSelector(state => state.persistedReducer.meta.meta);
        const user = useSelector(
            (state) => state.persistedReducer.home.userData
        );
        const orderData = useSelector(
            (state) => state.persistedReducer.home.orderSuccessData
        );
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>{metaDetails.title}</title>
                <meta name="description" content={metaDetails.description}></meta>
                <meta name="keywords" content={metaDetails.keywords}></meta>
            </Helmet>
            <div className="gs_myaccount gs_order_success">
                <Container className="gs_customer_container">
                    <Row className="w-100 m-auto">
                        <Col>
                            {/* <Image src={OrderImage} alt="Order Success"></Image> */}
                            <h1>Payment Failed</h1>
                            <p className="mt-3 d-inline-block">Your Order Was Completed Successfully.</p>

                            <p>Order Id : {orderData?.success_data?.order_id}</p>
                            <p>Order No : {orderData?.success_data?.order_no}</p>
                            {/* {
                                orderData?.success_data?.txn_id != 0 && <p>Transaction Id : {orderData?.success_data?.txn_id}</p>
                            } */}


                            <p><br /><span>  <Link to="/" target="blank">Go to Home</Link> </span> </p>
                        </Col>
                    </Row>
                </Container>
            </div>
    </>
  )
}

export default RedsysPayementFailed
