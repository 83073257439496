import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Heading from '../ui/Heading'

const CustomerExperience = () => {
  return (
    <section className="insta-section section-space pb-0">
      <Container className="home-showcase-container px-0">
        <Heading>
          <h2>Customer Experience</h2>
          <p>View our customers’ engagement moments from around the world</p>
        </Heading>

        <div className='image-grid-wrapper'>
          {Array.from({ length: 26 }).map((_, index) => <div className='item'>
            <Image src={require(`../../../../Assets/images/customer/${index + 1}.webp`)} loading="lazy" alt={"Customer"} />
          </div>)}
        </div>

      </Container>
    </section>
  )
}

export default CustomerExperience